import React from 'react';
import './App.css';

function Checkout() {
  return (
    <div className="Checkout">

    </div>
  );
}

export default Checkout;

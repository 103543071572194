import React from 'react';
import './App.css';

function Products() {
  return (
      <div className="Products">

      </div>
  );
}

export default Products;

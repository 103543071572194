import React from 'react';
import logo from './logo.png';
import './App.css';
import Products from "./Products";
import Checkout from "./Checkout";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Second Season Foundry
        </h1>
        <h2>Melting beer cans into bolo ties since 2023</h2>
      </header>
        <Products />
        <Checkout />
    </div>
  );
}

export default App;
